/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import { View, StyleSheet } from 'react-native'
import { TaskDetail, Input, StructureService, ErrorHandling, AuthService, Toast, AppContext, Checkbox } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { Panel } from '../component/da-panel'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { StructureForm } from './da-structure-form'
import { Footer } from '../da-request-info'
import { Color } from '@material-ui/lab'
import { GeneralForm } from '../da-general-info'

const useStyles = makeStyles({
  tableHeader: {
    fontWeight: 700,
    fontSize: 15,
    width: 'calc(100%/4)',
    paddingRight: 30,
    paddingLeft: 50,
    color: '#70777E'
  },
  tableHeaderCell: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E'
  },
  tableHeaderCellbody: {
    paddingRight: 30,
    paddingLeft: 50
  },
  tableHeaderRow: {
    backgroundColor: 'white'
  },
  tableDelete: {
    fontSize: 15,
    color: '#70777E'
  },
  tableActive: {
    fontSize: 15,
    color: '#74c075'
  },
  tableWaiting: {
    fontSize: 15,
    color: '#FF6F00'
  },
  tableError: {
    fontSize: 15,
    color: 'red'
  }
})

interface TableOrDetail {
  basicAgentType: string | undefined | null
  reportToAgent: string | undefined | null
  reportToType: string | undefined | null
  effectiveFrom: string | undefined | null
  effectiveTo: string | undefined | null
  description: string | undefined | null
}

type Props = {
  detail: TaskDetail.AgentOnboardNew
  form: Omit<UseFormReturn<StructureForm.Raw>, 'handleSubmit'>
  formGeneral: Omit<UseFormReturn<GeneralForm.Raw>, 'handleSubmit'>
  isHistory: boolean | null
  setStatusSave: (val: boolean) => void
  checkSubTabIndex: (val: number) => void
  setIsScroll?: (isCheck: boolean) => void
  isScroll?: boolean
}
export const DAtrucTure = (props: Props) => {
  const classes = useStyles()
  const { t } = useTranslation(['requestInfo', 'frequency'])
  const [loading, bindLoader] = useLoading(false)
  const [orDetail, setOrDetail] = React.useState<Array<TableOrDetail>>([])
  const [officeRuleCode2, setOfficeCode2] = React.useState<string>('')
  const [gaCode2, setgaCode2] = React.useState<string>('')
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const { reset, control, setValue, clearErrors, trigger, getValues, watch } = props.form
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const { rule4, rule_manual } = pipe(
    AuthService.userInfo,
    ZIO.flatMap((user) =>
      StructureService.getSalesStructureRule({
        processInstanceId: props.detail.structure.processInstanceId,
        taskId: props.detail.structure.id,
        agentCode: props?.formGeneral?.getValues('agCode'),
        clientId: props.detail.structure.clientId,
        businessKey: props.detail.structure.businessKey,
        author: user?.email,
        source: props.detail.structure.source,
        saleType: props.detail.salesType,
        subChannel:
          props?.formGeneral?.getValues('subChannel') === '-' ? '' : props?.formGeneral?.getValues('subChannel'),
        leaderCode: props?.formGeneral?.getValues('leaderCode'),
        residentialLocationCode: props?.formGeneral?.getValues('locationCodeResidential'),
        locationCode: props?.formGeneral?.getValues('AllocatedlocationCode'),
        eref: props.detail.eREF
      })
    ),
    ZIO.map((res) => {
      if (getValues('isSaveStructure') === false || res.responseStatus.code === '200') {
        setValue('gaCode1', res?.data?.gaCode1 ?? '')
        setValue('gaCode2', res?.data?.gaCode2 ?? '')
        setValue('officeCode1', res?.data?.officeCode1 ?? '')
        setValue('officeCode2', res?.data?.officeCode2 ?? '')
        setValue('regionCode', res?.data?.regionCode ?? '')
        setValue('saleZone', res?.data?.saleZone ?? '')
        setValue('serviceBranch', res?.data?.serviceBranch ?? '')
      }
      return {
        rule4: res.responseStatus.code === '1011' ? true : false,
        rule_manual: res.responseStatus.code === '1012' ? true : false
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    rule4: false,
    rule_manual: true
  }

  React.useEffect(() => {
    if (rule4 === true && props.detail.salesType !== 'DSR' && props.detail.salesType !== 'PTD') {
      setValue('gaCode2', gaCode2), setValue('officeCode2', officeRuleCode2)
    }
    if (rule_manual === true && props.detail.salesType !== 'DSR' && props.detail.salesType !== 'PTD') {
      setValue('gaCode2', gaCode2)
    }
    if (props.detail.salesType === 'PTD' || props.detail.salesType === 'DSR') {
      setValue('gaType', 'B0000')
      getValues('isSaveStructure') === false && setValue('agentCategory', props.detail.agCAT ?? '')
    } else {
      setValue('gaType', 'GAT07')
    }
  }, [rule4, gaCode2, officeRuleCode2, rule_manual])

  // get office code 2
  props.detail.salesType !== 'DSR' &&
    props.detail.salesType !== 'PTD' &&
    pipe(
      StructureService.getTableTT518(props?.formGeneral?.getValues('leaderCode') ?? ''),
      ZIO.flatMap((res) => {
        setOfficeCode2(res?.data?.code ?? '')
        if (
          props.detail.salesType === 'GALLERIE' &&
          (props?.formGeneral?.getValues('leaderCode') === 'AAA' ||
            props?.formGeneral?.getValues('leaderCode') === 'VVV')
        ) {
          setValue('officeCode2', getValues('officeCode1'))
        } else {
          setValue('officeCode2', res?.data?.code ?? '')
        }
        return StructureService.getTableTV604(res?.data?.code ?? '')
      }),
      ZIO.map((res) => {
        setgaCode2(res?.data?.code ?? '')
        if (
          props.detail.salesType === 'GALLERIE' &&
          (props?.formGeneral?.getValues('leaderCode') === 'AAA' ||
            props?.formGeneral?.getValues('leaderCode') === 'VVV')
        ) {
          setValue('gaCode2', getValues('gaCode1'))
        }
        getValues('isSaveStructure') === false && setValue('agentCategory', props.detail.agCAT ?? '')
        props.detail.salesType === 'PTD' && setValue('ref', props.detail.agCAT ?? '')
      }),
      ErrorHandling.runDidMount()
    )
  // get or detail table
  !!props?.formGeneral?.getValues('agCode') && !!props?.formGeneral?.getValues('leaderCode')
    ? pipe(
      StructureService.getTableOrDetail(
        props?.formGeneral?.getValues('agCode') ?? '',
        props?.formGeneral?.getValues('leaderCode') ?? '',
        props?.formGeneral?.getValues('salesType') ?? ''
      ),
      ZIO.flatMap((res) => {
        !!res && !!res.data ? setOrDetail(res?.data) : ''
        return ZIO.unit
      }),
      ErrorHandling.runDidMount()
    )
    : ''

  const getDataTableCodeRule4 = (officeCode1: string) => {
    pipe(
      ZIO.zipPar(StructureService.getTableTV604(officeCode1 ?? ''), StructureService.getTableT72(officeCode1 ?? '')),
      ZIO.flatMap(([agData, regionData]) => {
        const agCode1 = agData?.data?.code
        const regionCode = regionData?.data?.code

        setValue('gaCode1', agCode1 ?? '')
        setValue('regionCode', regionCode ?? '')

        return ZIO.zipPar(
          // check data if have call API else return null
          !!agCode1 ? StructureService.getTableT5696(regionData?.data?.code ?? '') : ZIO.succeed(null),
          !!regionCode ? StructureService.getTableT5628(regionData?.data?.code ?? '') : ZIO.succeed(null)
        )
      }),
      ZIO.flatMap(([saleZoneData, serviceData]) => {
        const saleZoneType = saleZoneData?.data?.code
        const serviceBranch = serviceData?.data?.code
        // check if have data and set value
        !!saleZoneData && setValue('saleZone', saleZoneType ?? '-')
        !!saleZoneData && setValue('serviceBranch', serviceBranch ?? '-')
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }
  const debounceOfficeCode1Fn = React.useCallback(_.debounce(getDataTableCodeRule4, 500), [])

  const getDataTableCodeRuleManual = (regionCode: string) => {
    pipe(
      ZIO.zipPar(StructureService.getTableT5696(regionCode ?? ''), StructureService.getTableT5628(regionCode ?? '')),
      ZIO.flatMap(([officeData, serviceData]) => {
        const officeCode = officeData?.data?.childCode
        const saleZone = officeData?.data?.code
        const serviceBranch = serviceData?.data?.code
        if (
          props.detail.salesType === 'GALLERIE' &&
          (props?.formGeneral?.getValues('leaderCode') === 'AAA' ||
            props?.formGeneral?.getValues('leaderCode') === 'VVV')
        ) {
          setValue('officeCode2', officeCode ?? '') //rule này set officeCode2 = officeCode1 khi leaderCode === AAA || VVV
          setValue('officeCode1', officeCode ?? '')
        } else {
          setValue('officeCode1', officeCode ?? '')
        }
        clearErrors('officeCode1')
        setValue('saleZone', saleZone ?? '')
        setValue('serviceBranch', serviceBranch ?? '')
        return !!officeCode ? StructureService.getTableTV604(officeCode ?? '') : ZIO.succeed(null)
      }),
      ZIO.map((res) => {
        const agCode1 = res?.data?.code ?? ''
        if (
          props.detail.salesType === 'GALLERIE' &&
          (props?.formGeneral?.getValues('leaderCode') === 'AAA' ||
            props?.formGeneral?.getValues('leaderCode') === 'VVV')
        ) {
          setValue('gaCode2', agCode1)
          setValue('gaCode1', agCode1 ?? '')
        } else {
          setValue('gaCode1', agCode1 ?? '')
        }
      }),
      ZIO.unsafeRun({})
    )
  }

  const debounceRegionCodeFn = React.useCallback(_.debounce(getDataTableCodeRuleManual, 500), [])
  const getStructure = pipe(
    StructureService.getStructureLog(props.detail.businessKey ?? ''),
    ZIO.map((res) => res),
    bindLoader,
    ErrorHandling.runDidMount()
  )
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (!!getStructure) {
      !!getStructure.data?.saleZone ? setValue('saleZone', getStructure.data?.saleZone) : ''
      !!getStructure.data?.regionCode ? setValue('regionCode', getStructure.data?.regionCode) : ''
      !!getStructure.data?.gaCode1 ? setValue('gaCode1', getStructure.data?.gaCode1) : ''
      !!getStructure.data?.isRecruitedByAL ? setValue('isRecruitedByAL', getStructure.data?.isRecruitedByAL) : false
      if (props.detail.salesType === 'PTD' || props.detail.salesType === 'DSR') {
        !!getStructure.data?.gaType ? setValue('gaType', 'B0000') : ''
      } else {
        !!getStructure.data?.gaType ? setValue('gaType', 'GAT07') : ''
      }
      !!getStructure.data?.galleries ? setValue('galleries', getStructure.data?.galleries) : ''
      !!getStructure.data?.agentCategory ? setValue('agentCategory', getStructure.data?.agentCategory) : ''
      !!getStructure.data?.officeCode1 ? setValue('officeCode1', getStructure.data?.officeCode1) : ''
      if (
        props.detail.salesType === 'GALLERIE' &&
        (props?.formGeneral?.getValues('leaderCode') === 'AAA' || props?.formGeneral?.getValues('leaderCode') === 'VVV')
      ) {
        !!getStructure.data?.officeCode1 ? setValue('officeCode2', getStructure?.data?.officeCode1) : ''
        !!getStructure.data?.gaCode1 ? setValue('gaCode2', getStructure?.data?.gaCode1) : ''
      } else {
        !!getStructure.data?.officeCode2 ? setValue('officeCode2', officeRuleCode2) : ''
        !!getStructure.data?.gaCode2 ? setValue('gaCode2', getStructure.data?.gaCode2) : ''
      }
      !!getStructure.data?.serviceBranch ? setValue('serviceBranch', getStructure.data?.serviceBranch) : ''
      !!getStructure.data?.ref ? setValue('ref', getStructure.data?.ref) : ''
      !!getStructure.data?.agentType ? setValue('agentType', getStructure.data?.agentType) : ''
    }
  }, [getStructure])

  const onReset = () => {
    reset(initData as any)
  }
  const initData = React.useMemo(() => {
    return {}
  }, [getStructure])
  const onSave = () => {
    return pipe(
      ZIO.fromPromise(async () => {
        return await trigger()
      }),
      ZIO.flatMap((valid) => {
        setValue('isSaveStructure', true)
        const structureData = getValues() as StructureForm.Validated
        if (valid) {
          return pipe(
            ZIO.zipPar(AuthService.userInfo, StructureService.getStructureLog(props.detail.businessKey ?? '')),
            ZIO.map(([res, getLog]) => {
              const data: StructureService.StructureLogRqData = {
                ...structureData,
                processInstanceId: props.detail.processInstanceId,
                taskId: props.detail?.taskId,
                agentCode: props?.formGeneral?.getValues('agCode'),
                clientId: props.detail?.idNo,
                businessKey: props.detail?.businessKey,
                author: res.email,
                source: props.detail.source,
                id: getLog.data?.id,
                eref: props.detail?.eREF,
                email: res.email,
                saleType: props.detail.salesType,
                status: JSON.stringify(structureData.isSaveStructure),
                submissionDate: props.detail.createdDate,
                transactionType: props.detail.transactionType,
                isRecruitedByAL: structureData.isRecruitedByAL
              }
              return data
            }),
            ZIO.flatMap((data) => {
              return StructureService.saveStructureLog(data)
            }),
            ZIO.tap((data) => {
              props?.setStatusSave?.(true)
              showToast(t('message:MS100003'), 'success')
              props.checkSubTabIndex(3)
              props?.setIsScroll && props?.setIsScroll(!props?.isScroll)
              return ZIO.unit
            }),
            ZIO.tapError((e) => {
              return ZIO.unit
            })
          )
        } else {
          return ZIO.succeed({})
        }
      }),
      // bindLoader,
      ZIO.unsafeRun({})
    )
  }
  return (
    <View style={{ flex: 1 }}>
      <View>
        <Panel title={t('RequestInfo:Structure')} isExand={true}>
          <View style={{ flex: 1 }}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Controller
                  name="saleZone"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('ServiceInquiry:SaleZone')}
                      onChange={onChange}
                      value={value ?? ''}
                      // errorMessage={error?.message}
                      maxLength={24}
                      multiline={false}
                      alwayShowUnderline={true}
                      disabled={true}
                    />
                  )}
                />
              </View>

              <View style={styles.cols}>
                <Controller
                  name="gaType"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('ServiceInquiry:GAType')}
                      onChange={onChange}
                      value={value ?? ''}
                      // errorMessage={error?.message}
                      maxLength={24}
                      multiline={false}
                      alwayShowUnderline={true}
                      disabled={true}
                    />
                  )}
                />
              </View>
              <View style={styles.col}>
                <Controller
                  name="agentType"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('ServiceInquiry:AgentType')}
                      onChange={onChange}
                      value={'AG'}
                      // errorMessage={error?.message}
                      maxLength={24}
                      multiline={false}
                      alwayShowUnderline={true}
                      disabled={true}
                    />
                  )}
                />
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="officeCode1"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:OfficeCode1')}
                        onChange={(val) => {
                          onChange(val)
                          rule4 && debounceOfficeCode1Fn(val)
                        }}
                        value={value ?? ''}
                        errorMessage={error?.message}
                        // maxLength={24}
                        multiline={false}
                        alwayShowUnderline={true}
                        disabled={!rule4}
                      />
                    )}
                  />
                </View>

                <View style={styles.cols}>
                  <Controller
                    name="gaCode1"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:GACode1')}
                        onChange={onChange}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        // maxLength={24}
                        multiline={false}
                        alwayShowUnderline={true}
                        disabled={true}
                      />
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="regionCode"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:RegionCode')}
                        onChange={(val) => {
                          onChange(val)
                          rule_manual && debounceRegionCodeFn(val)
                        }}
                        value={value ?? ''}
                        errorMessage={error?.message}
                        maxLength={3}
                        multiline={false}
                        alwayShowUnderline={true}
                        disabled={!rule_manual}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="officeCode2"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:OfficeCode2')}
                        onChange={onChange}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        // maxLength={24}
                        multiline={false}
                        alwayShowUnderline={true}
                        disabled={true}
                      />
                    )}
                  />
                </View>

                <View style={styles.cols}>
                  <Controller
                    name="gaCode2"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:GACode2')}
                        onChange={onChange}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        // maxLength={24}
                        multiline={false}
                        alwayShowUnderline={true}
                        disabled={true}
                      />
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="serviceBranch"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:ServiceBranch')}
                        onChange={onChange}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        // maxLength={24}
                        multiline={false}
                        alwayShowUnderline={true}
                        disabled={true}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="galleries"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:Gallerie')}
                        onChange={onChange}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        // maxLength={24}
                        multiline={false}
                        alwayShowUnderline={true}
                      />
                    )}
                  />
                </View>

                <View style={styles.cols}>
                  <Controller
                    name="agentCategory"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:AgentCategory')}
                        onChange={onChange}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        maxLength={3}
                        multiline={false}
                        alwayShowUnderline={true}
                      />
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="ref"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('ServiceInquiry:Ref')}
                        onChange={onChange}
                        value={value ?? ''}
                        maxLength={3}
                        multiline={false}
                        alwayShowUnderline={true}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="isRecruitedByAL"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Checkbox
                        title={t('ServiceInquiry:PruVentureRecruitedByAgencyLeader')}
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
          </View>
        </Panel>
      </View>
      <View>
        <Panel title={t('RequestInfo:ORDetails')} isExand={true}>
          <View style={{ flex: 1 }}>
            <View
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#D3DCE6',
                marginHorizontal: 16,
                marginVertical: 16
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  maxHeight: 350
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell align={'center'} className={classes.tableHeaderCell}>
                        {t('ServiceInquiry:BasicAgentType')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableHeaderCell}>
                        {t('ServiceInquiry:ReportToAgent')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableHeaderCell}>
                        {t('ServiceInquiry:ReportToType')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableHeaderCell}>
                        {t('ServiceInquiry:EffectiveFrom')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableHeaderCell}>
                        {t('ServiceInquiry:EffectiveTo')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableHeader}>
                        {t('ServiceInquiry:Description')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orDetail.map((row, i) => {
                      return (
                        <TableRow key={`TableRow-${i}`} className={classes.tableHeaderRow}>
                          <TableCell align={'center'}>{row.basicAgentType ?? '-'}</TableCell>
                          <TableCell align={'center'}>{row.reportToAgent ?? '-'}</TableCell>
                          <TableCell align={'center'}>{row.reportToType ?? '-'}</TableCell>
                          <TableCell align={'center'}>{row.effectiveFrom ?? '-'}</TableCell>
                          <TableCell align={'center'}>{row.effectiveTo ?? '-'}</TableCell>
                          <TableCell align={'center'}>{row.description ?? '-'}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          </View>
        </Panel>
      </View>
      {props.isHistory === true ? null : <Footer isLoading={loading} onReset={onReset} onSubmit={onSave} />}
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  container: {
    width: '100%',
    padding: 30,
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    marginBottom: 16
  },
  containers: {
    width: '100%',
    padding: 30,
    borderRadius: 8,
    marginBottom: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6'
  },
  col: {
    width: '33.33333333333%'
  },
  cols: {
    width: '33.33333333333%',
    paddingHorizontal: 30
  },
  row: {
    flexDirection: 'row'
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    height: 36,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' }
})
