import {
    form2,
    assets,
    ChangeClientInfoService,
    DatePicker,
    DuplicateTable,
    ErrorHandling,
    GeneralService,
    ImgUploadMutiple,
    Input,
    ModalAction,
    ModalComponent,
    RadioButtonGroup,
    SC,
    SelectOption,
    SelectSearch,
    Title
  } from '@pulseops/common'
  import { FieldText, SectionCol, SectionContent, SectionRow, Error, Column } from '@pulseops/submission/common'
  import React from 'react'
  import { useTranslation } from 'react-i18next'
  import { StyleSheet, Text, View, useWindowDimensions } from 'react-native'
  import { ScrollView } from 'react-native-gesture-handler'
  import { UseFormReturn, UseFieldArrayReturn, useForm, Controller } from 'react-hook-form'
  //   import { RiderAlterationFormData } from './rider-alteration.form'
  import { pipe } from 'fp-ts/lib/function'
  import { ZIO } from '@mxt/zio'
  import * as O from 'fp-ts/lib/Option'
  import {
    RiderAlterationConst,
    RiderAlterationFormData,
    RiderAlterationData,
    ChangeContactInfo
  } from '../../service/model/'
  import moment from 'moment'
  // import { DuplicateTable, SC } from '@pulseops/submission/common'
  import * as _ from 'lodash'
  import { useLoading } from '@mxt/zio-react'
  import { LinearProgress } from '@material-ui/core'
  
  export type MajorAddNewLAModalProps = {
    title: string
    isVisible: boolean
    veriDetailData?: RiderAlterationData.NewLifeAssured
    submissionDetailData?: RiderAlterationFormData.NewLAInfo
    policyInfoData?: RiderAlterationData.PolicyInfoData
    isConfirmed?: boolean
    isDetailView?: boolean
    detailIndex?: number
    riderAlterationForm?: UseFormReturn<RiderAlterationFormData.RiderAlteration>
    // NewRiderLAList: UseFieldArrayReturn<RiderAlterationFormData.RiderAlteration, 'newRiderLAList', 'id'>
    onClose: () => void
    onSuccess: (newItem: RiderAlterationFormData.NewLAInfo) => void
  }
  
  export const MajorAddNewLAModal = (props: MajorAddNewLAModalProps) => {
    const { width, height } = useWindowDimensions()
    const { t, i18n } = useTranslation()
    const [isLoading, bindLoader] = useLoading(false)
    const { control, getValues, trigger, watch, formState, reset, setValue } = useForm<RiderAlterationFormData.NewLAInfo>(
      {
        defaultValues: {
          mobilePhone: '',
          dupPhoneMessage: '',
          dupEmailMessage: ''
        },
        mode: 'all'
      }
    )
  
    const phoneDups = watch('dupPhone') ?? []
    const emailDups = watch('dupEmail') ?? []
  
    const getDocumentNationalID = React.useMemo(() => {
      return RiderAlterationConst.DocumentNationalID.map((item) => ({
        label: i18n.language === 'en' ? item.nameEn : item.nameVi,
        value: item.code
      }))
    }, [i18n.language])
    const getDocumentPasport = React.useMemo(() => {
      return RiderAlterationConst.DocumentPasport.map((pasport) => ({
        label: i18n.language === 'en' ? pasport.nameEn : pasport.nameVi,
        value: pasport.code
      }))
    }, [i18n.language])
    const {
      provinceList,
      occupationList,
      mobileCodeOptions,
      countriesOptions,
      averageIncomeOptions,
      relationshipOptions
    } = pipe(
      ZIO.zipPar(
        GeneralService.getProvinces,
        GeneralService.getOccupations,
        GeneralService.getMobileCodes,
        GeneralService.getCountries,
        GeneralService.getSalaries,
        GeneralService.getRelationship()
      ),
      ZIO.map(([provinceData, occupationData, mobileCodeData, countryData, AverageIncomeArr, relationships]) => {
        const provinceList = provinceData.map((item) => ({ label: item.name, value: item.code }))
        const occupationList = occupationData.map((item) => ({ label: item.name, value: item.code }))
        const mobileCodeOptions = mobileCodeData.map((m) => ({
          value: m.code,
          label: `+${m.code}`
        }))
        const countriesOptions = countryData.map((c) => ({
          value: c.code,
          label: c.name
        }))
        const averageIncomeOptions = AverageIncomeArr.map((incomeItem) => ({
          value: incomeItem.code,
          label: i18n.language === 'en' ? incomeItem.nameEn : incomeItem.name
        }))
        const relationshipOptions = relationships
          .filter((r) => r.changeClientInfo === 'Y')
          .map((r) => ({
            label: i18n.language === 'vi' ? r.nameVn : r.nameEn,
            value: r.code
          }))
        setValue('mobilePhoneCode', mobileCodeOptions.find((x) => x.value === '84') ?? { value: '', label: '' })
        if (props.veriDetailData?.documentType === '01') {
          intialData(
            getDocumentNationalID,
            countriesOptions,
            occupationList,
            averageIncomeOptions,
            mobileCodeOptions,
            relationshipOptions
          )
        } else if (props.veriDetailData?.documentType === '03') {
          intialData(
            getDocumentPasport,
            countriesOptions,
            occupationList,
            averageIncomeOptions,
            mobileCodeOptions,
            relationshipOptions
          )
        } else {
          intialData(
            provinceList,
            countriesOptions,
            occupationList,
            averageIncomeOptions,
            mobileCodeOptions,
            relationshipOptions
          )
        }
  
        return {
          provinceList,
          occupationList,
          mobileCodeOptions,
          countriesOptions,
          averageIncomeOptions,
          relationshipOptions
        }
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    ) ?? {
      provinceList: [],
      occupationList: [],
      mobileCodeOptions: [],
      countriesOptions: [],
      averageIncomeOptions: [],
      relationshipOptions: []
    }
  
    React.useEffect(() => {
      return () => {
        reset({
          mobilePhone: '',
          identifyDocument: [],
          dupPhoneMessage: '',
          dupEmailMessage: ''
        })
      }
    }, [props.isVisible])
  
    const intialData = (
      provinceList: SelectOption[],
      countriesOptions: SelectOption[],
      occupationList: SelectOption[],
      averageIncomeList: SelectOption[],
      mobileCodeOptions: SelectOption[],
      relationshipOptions: SelectOption[]
    ) => {
      if (!!props.veriDetailData) {
        const documentType = convertValuetoSelectOption(props.veriDetailData.documentType, getDocumentList())
        const issuePlace = convertValuetoSelectOption(props.veriDetailData.issuePlace, provinceList)
        const gender = convertValuetoSelectOption(props.veriDetailData.gender, genderOptions)
        const averageIncome = convertValuetoSelectOption(props.veriDetailData.averageIncome, averageIncomeList)
        const nationality = convertValuetoSelectOption(props.veriDetailData.nationality, countriesOptions)
        const occupation = convertValuetoSelectOption(props.veriDetailData.occupation, occupationList)
        const mobilePhoneCode = convertValuetoSelectOption(props.veriDetailData.mobileCode, mobileCodeOptions)
        const dateOfBirth = moment(props.veriDetailData.dateOfBirth).toDate()
        const issueDate = moment(props.veriDetailData.issueDate).toDate()
        const USTaxDeclaration = props.veriDetailData.isUSTax ? 'Y' : 'N'
        const dupPhone = getDuplicatedList(props.veriDetailData.duplicatePhones, relationshipOptions)
        const dupEmail = getDuplicatedList(props.veriDetailData.duplicateEmails, relationshipOptions)
        reset({
          surname: props.veriDetailData.lastName,
          firstName: props.veriDetailData.firstName,
          documentType: documentType,
          IDNumber: props.veriDetailData.idNumber,
          issuePlace: issuePlace,
          issuedDate: issueDate,
          USTaxDeclaration: USTaxDeclaration,
          dateOfBirth: dateOfBirth,
          gender: gender,
          mobilePhoneCode: mobilePhoneCode,
          mobilePhone: props.veriDetailData.mobilePhone,
          email: props.veriDetailData.email,
          nationality: nationality,
          occupation: occupation,
          jobDescription: props.veriDetailData.jobDescription,
          jobTitle: props.veriDetailData.jobTitle,
          companyName: props.veriDetailData.companyName,
          companyAddress: props.veriDetailData.companyAddress,
          averageIncome: averageIncome,
          dupPhone: dupPhone,
          dupEmail: dupEmail
        })
      } else if (props.submissionDetailData) {
        reset(props.submissionDetailData)
      }
    }
  
    const predicateAgent = (relationship: RiderAlterationData.RelationShip) => relationship.role === 'AG'
  
    const anyAgentPhone = React.useMemo(() => phoneDups?.find(predicateAgent), [phoneDups])
    const anyAgentEmail = React.useMemo(() => emailDups?.find(predicateAgent), [emailDups])
  
    React.useEffect(() => {
      if (anyAgentPhone) {
        setValue('dupPhoneMessage', t('message:MS050225', { agent: _.get(anyAgentPhone, 'clientName') }))
      } else {
        setValue('dupPhoneMessage', '')
      }
    }, [anyAgentPhone])
  
    React.useEffect(() => {
      if (anyAgentEmail) {
        setValue('dupEmailMessage', t('message:MS050227', { agent: _.get(anyAgentEmail, 'clientName') }))
      } else {
        setValue('dupEmailMessage', '')
      }
    }, [anyAgentEmail])
  
    const getDuplicatedList = (
      dupClientList: Array<{
        clientName: string
        clientID: string
        relationshipType: string
      }>,
      relationshipArr: SelectOption[]
    ) => {
      const dupList = dupClientList.map((item) => {
        const relationship = pipe(
          relationshipArr.find((x) => x.value === item.relationshipType),
          O.fromNullable,
          O.map((x) => ({ label: x.label, value: x.value })),
          O.getOrElse(() => ({ label: '', value: '' }))
        )
        return {
          clientId: item.clientID,
          clientName: item.clientName,
          relationship: relationship,
          role: '',
          secuityNo: '',
          dob: '',
          gender: '',
          poName: item.clientName
        }
      })
      return dupList
    }
  
    const isSameClient = (relationship: RiderAlterationData.RelationShip): boolean => {
      return (
        relationship.clientName === props.policyInfoData?.poName &&
        relationship.dob === props.policyInfoData?.dob &&
        relationship.gender === props.policyInfoData?.gender &&
        relationship.secuityNo === props.policyInfoData?.secuityNo
      )
    }
  
    const getDupPhone = (phone: string, clientId: string) => {
      if (!!phone) {
        pipe(
          ChangeClientInfoService.getDuplicatePhoneInfo(phone, clientId),
          ZIO.map((phones) => {
            const phoneDup: RiderAlterationData.RelationShip[] = phones.map((phone) => ({
              clientId: phone.clientId,
              clientName: phone.clientName,
              relationship: null,
              role: phone.role,
              secuityNo: phone.secuityNo,
              dob: phone.dob,
              gender: phone.gender,
              poName: phone.clientName
            }))
            setValue('dupPhone', phoneDup?.filter((p) => !isSameClient(p)) ?? [])
            return phones
          }),
          bindLoader,
          ZIO.unsafeRun({})
        )
      }
    }
  
    const getDupEmail = (email: string, clientId: string) => {
      if (!!email) {
        pipe(
          ChangeClientInfoService.getDuplicateEmailInfo(email, clientId),
          ZIO.map((emails) => {
            const emailDup: RiderAlterationData.RelationShip[] = emails.map((email) => ({
              clientId: email.clientId,
              clientName: email.clientName,
              relationship: null,
              role: email.role,
              secuityNo: email.secuityNo,
              dob: email.dob,
              gender: email.gender,
              poName: email.clientName
            }))
            setValue('dupEmail', emailDup?.filter((e) => !isSameClient(e)) ?? [])
            return emails
          }),
          bindLoader,
          ZIO.unsafeRun({})
        )
      }
    }
  
    const convertValuetoSelectOption = (code: string, optionList: SelectOption[]) =>
      pipe(
        optionList.find((item) => item.value === code),
        O.fromNullable,
        O.map((item) => item),
        O.getOrElse(() => ({ label: '', value: '' }))
      )
  
    const genderOptions = React.useMemo(() => {
      return RiderAlterationConst.Gender.map((g) => ({
        label: i18n.language === 'vi' ? g.nameVi : g.nameEn,
        value: g.value
      }))
    }, [i18n.language])
  
    const getDocumentList = () => {
      const documentTypeArr = (props.detailIndex || -1) < 0 ? RiderAlterationConst.DocumentTypeList.filter((x)=> x.code !== "00") : RiderAlterationConst.DocumentTypeList
      return documentTypeArr.map((document) => {
        return {
          label: i18n.language === 'en' ? document.nameEn : document.nameVi,
          value: document.code
        }
      })
    }
  
    const transOptionDocument = React.useMemo(() => {
      if (watch('documentType')?.value === '01') {
        return getDocumentNationalID
      } else if (watch('documentType')?.value === '00' || watch('documentType')?.value === '02') {
        return provinceList
      } else {
        return getDocumentPasport
      }
    }, [watch('documentType')?.value])
  
    const saveNewLA = async () => {
      const isFormValid = await trigger()
      if (isFormValid && !watch('dupPhoneMessage') && !watch('dupEmailMessage')) {
        const newItem = getValues()
        props.onSuccess(newItem)
        props.onClose()
      }
    }
  
    const modalActions: ModalAction[] = !!props.isDetailView
      ? []
      : [
          {
            text: t('common:Cancel'),
            type: 'outline',
            action: () => props.onClose(),
            disabled: false,
            loading: false,
            onlyWide: false,
            style: riderModalStyles.btnCancel
          },
          {
            text: t('submission:Confirm'),
            type: 'filled',
            action: () => saveNewLA(),
            disabled: !formState.isValid || !!watch('dupPhoneMessage') || !!watch('dupEmailMessage'),
            loading: false,
            onlyWide: false,
            style: riderModalStyles.btnCancel
          }
        ]
  
    return (
      <ModalComponent
        title={props.title}
        onClose={props.onClose}
        modalWidth={Math.min(width * 0.9, 1024)}
        modalHeight={Math.min(height * 0.9, 768)}
        visible={props.isVisible}
        actions={modalActions}
        titleStyle={riderModalStyles.modalTitle}
        contentStyle={riderModalStyles.modalContent}
      >
        <ScrollView style={riderModalStyles.container}>
          <SectionContent>
            <SectionRow>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="surname"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('requestInfo:Surname') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('requestInfo:Surname')}
                      required={true}
                      placeholder={'Nhập'}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={120}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="firstName"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020001', { field: t('requestInfo:GivenName') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('requestInfo:GivenName')}
                      required={true}
                      placeholder={'Nhập'}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={120}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="documentType"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('RiderAlteration:DocumentType') })
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <SelectSearch
                      key={getValues('documentType')?.value}
                      {...field}
                      required={true}
                      label={t('RiderAlteration:DocumentType')}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      options={getDocumentList()}
                      onChange={(val) => {
                        field.onChange(val)
                        setValue('issuePlace', {
                          label: '',
                          value: ''
                        })
  
                        // onChangeNationalityEvent(val)
                      }}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="IDNumber"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('RiderAlteration:IDNumber') })
                    },
                    validate: () => {
                      return watch('IDNumber').length < 2 || watch('IDNumber').length > 24
                        ? `${t('message:MS050216')}`
                        : true
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      title={t('RiderAlteration:IDNumber')}
                      required={true}
                      {...field}
                      maxLength={24}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="issuedDate"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('RiderAlteration:IssuedDate') })
                    },
                    validate: (val) => {
                      if (!moment(val).isValid()) {
                        return `${t('form:error_invalid_date')}`
                      } else if (moment(val).isValid() && moment(val).valueOf() > moment().valueOf()) {
                        return `${t('message:MS050292')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('RiderAlteration:IssuedDate')}
                      required
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message || ''}
                      disabled={props.isConfirmed}
                      maxDate={new Date()}
                      maxDateMessage={''}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="issuePlace"
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      key={watch('issuePlace')?.value}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      // required={true}
                      label={t('RiderAlteration:IssuedPlace')}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      options={transOptionDocument}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
  
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="USTaxDeclaration"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('RiderAlteration:USTaxDeclaration') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <RadioButtonGroup
                      title={t('RiderAlteration:USTaxDeclaration')}
                      required
                      colLength={'100%'}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      options={RiderAlterationConst.taxDeclarationList.map((item) => ({
                        id: item.id,
                        label: t(item.label)
                      }))}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('requestInfo:DOB') })
                    },
                    validate: (val) => {
                      if (!moment(val).isValid()) {
                        return `${t('form:error_invalid_date')}`
                      } else if (moment(val, 'YYYY/MM/DD').valueOf() >= moment(moment().format('YYYY/MM/DD')).valueOf()) {
                        return `${t('message:MS050022')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('requestInfo:DOB')}
                      required
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message || ''}
                      disabled={props.isConfirmed}
                      maxDate={new Date()}
                      maxDateMessage={''}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="gender"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('requestInfo:Gender') })
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <SelectSearch
                      key={getValues('gender')?.value}
                      {...field}
                      required={true}
                      label={t('requestInfo:Gender')}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      options={genderOptions}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="nationality"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('requestInfo:Nationality') })
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      // key={bankTransferForm.base.getValues('branch')?.value}
                      value={value}
                      onBlur={onBlur}
                      onChange={(val) => {
                        onChange(val)
                        // onChangeNationalityEvent(val)
                      }}
                      required={true}
                      label={t('requestInfo:Nationality')}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      options={countriesOptions}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <FieldText
                  text={t('requestInfo:MobilePhone')}
                  fieldStyles={{ marginBottom: 0 }}
                  // isRequired={true}
                ></FieldText>
                <View style={riderModalStyles.phoneContent}>
                  <View style={{ flex: 30, marginRight: 20 }}>
                    <Controller
                      control={control}
                      name="mobilePhoneCode"
                      rules={{
                        required: {
                          value: true,
                          message: t('message:MS020009', { field: t('requestInfo:Nationality') })
                        }
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <SelectSearch
                          {...field}
                          options={mobileCodeOptions}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                          hideLabel
                          placeholder={'+1'}
                          disabled={props.isConfirmed}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flex: 70 }}>
                    <Controller
                      control={control}
                      name="mobilePhone"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: t('message:MS020001', { field: t('requestInfo:MobilePhone') })
                      //   }
                      // }}
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <Input
                          value={value}
                          onChange={(val) => {
                            onChange(val)
                            !val && setValue('dupPhone', [])
                          }}
                          onBlur={() => {
                            onBlur()
                            getDupPhone(watch('mobilePhone'), props.policyInfoData?.customerId ?? '')
                          }}
                          inputType="number"
                          disabled={props.isConfirmed}
                          maxLength={10}
                        />
                      )}
                    />
                  </View>
                </View>
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    validate: (val) => {
                      return !!val && !form2.email.validate(val)
                        ? `${t('message:MS050013', { field: t('submission:Email') })}`
                        : true
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('submission:Email')}
                      placeholder={'Nhập'}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        !val && setValue('dupEmail', [])
                      }}
                      onBlur={() => {
                        onBlur()
                        getDupEmail(watch('email'), props.policyInfoData?.customerId ?? '')
                      }}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col12}>
                {isLoading && <LinearProgress color="secondary" />}
                {anyAgentPhone && !props.isConfirmed ? (
                  <SC.Padding>
                    <SC.ErrorText>{t('message:MS050225', { agent: _.get(anyAgentPhone, 'clientName') })}</SC.ErrorText>
                  </SC.Padding>
                ) : (
                  phoneDups &&
                  phoneDups.length > 0 && (
                    <SC.Container>
                      {!props.isConfirmed && (
                        <SC.Padding vertical={10}>
                          <SC.ErrorText>{t('submission:PhoneDuplicatedWithOther')}</SC.ErrorText>
                        </SC.Padding>
                      )}
                      {/* {_.get(formState.errors, 'dupPhone') && (
                        <SC.Padding vertical={5}>
                          <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                        </SC.Padding>
                      )} */}
                      <Controller
                        control={control}
                        name={'dupPhone'}
                        rules={{
                          validate: (arr) => {
                            return arr && arr.length > 0 && arr.some((x) => !x.relationship || !x.relationship.value)
                              ? `${t('message:MS050228')}`
                              : true
                          }
                        }}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                          console.log('error :', error?.message)
                          return (
                            <View>
                              {!props.isConfirmed && (
                                <SC.Padding vertical={5}>
                                  <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                                </SC.Padding>
                              )}
                              <DuplicateTable
                                key={`dup_phone_table${props.isConfirmed}`}
                                value={value}
                                onChange={onChange}
                                disable={props.isConfirmed}
                                relationshipOptions={relationshipOptions}
                              />
                            </View>
                          )
                        }}
                      />
                    </SC.Container>
                  )
                )}
  
                {anyAgentEmail && !props.isConfirmed ? (
                  <SC.Padding vertical={10}>
                    <SC.ErrorText>{t('message:MS050227', { agent: _.get(anyAgentEmail, 'clientName') })}</SC.ErrorText>
                  </SC.Padding>
                ) : (
                  emailDups &&
                  emailDups.length > 0 && (
                    <SC.Container>
                      {!props.isConfirmed && (
                        <SC.Padding vertical={10}>
                          <SC.ErrorText>{t('submission:EmailDuplicatedWithOther')}</SC.ErrorText>
                        </SC.Padding>
                      )}
                      {/* {_.get(formState.errors, 'dupEmail') && (
                        <SC.Padding vertical={5}>
                          <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                        </SC.Padding>
                      )} */}
                      <Controller
                        control={control}
                        name={'dupEmail'}
                        rules={{
                          validate: (arr) => {
                            return arr && arr.length > 0 && arr.some((x) => !x.relationship || !x.relationship.value)
                              ? `${t('message:MS050228')}`
                              : true
                          }
                        }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <View>
                              {!props.isConfirmed && (
                                <SC.Padding vertical={5}>
                                  <SC.ErrorText>{t('message:MS050228')}</SC.ErrorText>
                                </SC.Padding>
                              )}
                              <DuplicateTable
                                key={`dup_mail_table${props.isConfirmed}`}
                                value={value}
                                onChange={onChange}
                                disable={props.isConfirmed}
                                relationshipOptions={relationshipOptions}
                              />
                            </View>
                          )
                        }}
                      />
                    </SC.Container>
                  )
                )}
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col12}>
                <FieldText text={t('submission:RelativeDocument')} isRequired={true}></FieldText>
                <Controller
                  control={control}
                  name={'identifyDocument'}
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS050232')
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Column>
                        <ImgUploadMutiple
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          timeFormat={'DD/MM/YYYY HH:mm'}
                          disabled={props.isConfirmed}
                        />
                        {error?.message && <Error message={error.message} />}
                      </Column>
                    )
                  }}
                />
              </SectionCol>
            </SectionRow>
            <Title wrapperStyle={riderModalStyles.secondLine} title={t('Occupation Information')}></Title>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="occupation"
                  rules={{
                    required: {
                      value: true,
                      message: t('message:MS020009', { field: t('submission:Occupation') })
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <SelectSearch
                      key={getValues('occupation')?.value}
                      {...field}
                      required={true}
                      label={t('submission:Occupation')}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      options={occupationList}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="jobDescription"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      title={t('submission:JobDescription')}
                      {...field}
                      disabled={props.isConfirmed}
                      maxLength={100}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="jobTitle"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      title={t('submission:JobTitle')}
                      {...field}
                      disabled={props.isConfirmed}
                      maxLength={100}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="companyName"
                  render={({ field, fieldState: { error } }) => (
                    <Input title={t('submission:CompanyName')} {...field} maxLength={100} disabled={props.isConfirmed} />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={riderModalStyles.secondLine}>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="companyAddress"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      title={t('submission:CompanyAddress')}
                      {...field}
                      maxLength={100}
                      disabled={props.isConfirmed}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol sectionStyles={riderModalStyles.col6}>
                <Controller
                  control={control}
                  name="averageIncome"
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: t('message:MS020009', { field: t('RiderAlteration:AverageIncome') })
                  //   }
                  // }}
                  render={({ field, fieldState: { error } }) => (
                    <SelectSearch
                      key={getValues('averageIncome')?.value}
                      {...field}
                      // required={true}
                      label={t('RiderAlteration:AverageIncome')}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      options={averageIncomeOptions}
                      disabled={props.isConfirmed}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
          </SectionContent>
        </ScrollView>
      </ModalComponent>
    )
  }
  const riderModalStyles = StyleSheet.create({
    container: {
      padding: 20
    },
    modalTitle: {
      textAlign: 'center'
    },
    modalContent: {
      backgroundColor: '#EEEEEE'
    },
    btnCancel: {
      minHeight: 39,
      marginRight: 30
    },
    col8: {
      width: '100%',
      maxWidth: '66.6666666667%'
    },
    col6: {
      width: '100%',
      maxWidth: '50%'
    },
    col12: {
      width: '100%',
      maxWidth: '100%'
    },
    secondLine: {
      marginTop: 20
    },
    phoneContent: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  })