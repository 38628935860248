import { Task, Throwable, ZIO } from '@mxt/zio'
import { HttpClient } from '@mxt/zio-http-client'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { AuthService } from './auth'
import { AppConfig } from './config'
import * as E from 'fp-ts/Either'
import { Maybe } from '@mxt/zio/codec'

export namespace POApi {
  export const buildUrl = (api: string): Task<string> =>
    pipe(
      AppConfig.get,
      ZIO.map((cf) => {
        let aplUrl = api
        const currVersion = cf.version.split('.').join('-') || '1-0-0'
        const validPrefix = [
          'wf-api',
          'pulseops',
          'digitalclaim',
          'pvadigital',
          'business-pulse',
          'authentication',
          'premium-collection-internal',
          'claim-rest',
          'document-generation',
          'premium-collection',
          'data-extraction',
          'distribution-agents-service',
          'cep-rest',
          'distribution-agents-rest',
          'inbox-rest',
          'cs-api',
          'cs-rest',
          'pvafilenet',
          'ocr-rest',
          'banca-sea',
          'pva-cs-avaya-gateway',
          'independence-rest',
          'independence-query',
          'azurestorage',
          'administration-rest',
          'communication-rest',
          'feedback-rest-internal'
        ]
        const prefix = validPrefix.find((s) => aplUrl.startsWith(s))
        if (prefix) {
          aplUrl = aplUrl.replace(prefix, `${prefix}/${currVersion}`)
        }
        return `${cf.apiUrl}/${aplUrl}`
      })
    )

  export const getConfig =
    (_config: AxiosRequestConfig = {}) =>
      (url: string, config: AxiosRequestConfig = {}) =>
        <C extends t.Mixed>(response: C) =>
          pipe(
            ZIO.zipPar(AuthService.token, AuthService.getLoginType, buildUrl(url)),
            ZIO.flatMap(([token, loginType, hostName]) =>
              HttpClient.get({
                ..._config,
                ...config,
                headers: {
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType,
                  ..._config.headers,
                  ...config.headers
                }
              })(hostName, response)
            )
          )

  export const internalAuthorizedGet = (url: string, config: AxiosRequestConfig = {}) =>
    <C extends t.Mixed>(response: C) =>
      pipe(
        ZIO.zipPar(AppConfig.get, buildUrl(url)),
        ZIO.flatMap(([resConfig, hostName]) =>
          HttpClient.get({
            ...config,
            headers: {
              'Ocp-Apim-Subscription-Key': resConfig.OcpApimSubscriptionKey,
              ...config.headers,
            }
          })(hostName, response)
        )
      )

  export const internalAuthorizedPost =
    <A>(url: string, config?: AxiosRequestConfig) =>
      <C extends t.Mixed>(response: C) =>
        (body: A) =>
          pipe(
            ZIO.zipPar(AppConfig.get, buildUrl(url)),
            ZIO.flatMap(([resConfig, hostName]) =>
              HttpClient.post({
                ...config,
                headers: {
                  'Ocp-Apim-Subscription-Key': resConfig.OcpApimSubscriptionKey,
                  ...config?.headers
                }
              })(hostName)(response)(body)
            )
          )


  export const get = getConfig()

  export const post =
    <A>(url: string, config?: AxiosRequestConfig) =>
      <C extends t.Mixed>(response: C) =>
        (body: A) =>
          pipe(
            ZIO.zipPar(AuthService.token, AuthService.getLoginType, buildUrl(url)),
            ZIO.flatMap(([token, loginType, hostName]) =>
              HttpClient.post({
                ...config,
                headers: {
                  ...config?.headers,
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })(hostName)(response)(body)
            )
          )

  export const put =
    <A>(url: string, config?: AxiosRequestConfig) =>
      <C extends t.Mixed>(response: C) =>
        (body: A) =>
          pipe(
            ZIO.zipPar(AuthService.token, AuthService.getLoginType, buildUrl(url)),
            ZIO.flatMap(([token, loginType, hostName]) =>
              HttpClient.put({
                headers: {
                  ...config?.headers,
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })(hostName)(response)(body)
            )
          )

  type ResponseError = (e: AxiosError) => Throwable
  const errRes = t.type({
    responseStatus: t.type({
      code: t.number,
      errors: t.array(
        t.type({
          code: t.string,
          message: t.string
        })
      ),
      message: t.string
    })
  })

  const importErrorInfo = t.type({
    code: t.string,
    message: t.string,
    errors: Maybe(t.string)
  })

  export const baseError: ResponseError = (e: AxiosError) =>
    pipe(
      errRes.decode(e.response?.data),
      E.fold(
        (e) => Throwable(e),
        (a) => Throwable(`${a.responseStatus.errors[0].code} - ${a.responseStatus.errors[0].message}`)
      )
    )

  export const importError: ResponseError = (e: AxiosError) =>
    pipe(
      importErrorInfo.decode(e.response?.data),
      E.fold(
        (e) => Throwable(e),
        (a) => Throwable(`${a.code}`)
      )
    )
  export const headerError: ResponseError = (e: AxiosError) =>
    pipe(
      t
        .type({
          header: errRes
        })
        .decode(e.response?.data),
      E.fold(
        (e) => Throwable(e),
        (a) => Throwable(`${a.header.responseStatus.errors[0].code} - ${a.header.responseStatus.errors[0].message}`)
      )
    )

  export const postError =
    (config?: AxiosRequestConfig) =>
      <Body>(url: string) =>
        <C extends t.Mixed>(response: C, errMapper: ResponseError = baseError) =>
          (body?: Body) =>
            pipe(
              ZIO.zipPar(AuthService.token, AuthService.getLoginType, buildUrl(url)),
              ZIO.flatMap(([token, loginType, hostName]) =>
                pipe(
                  HttpClient.postAxios({
                    ...config,
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'X-Authen-Vendor': loginType
                    }
                  })(hostName)(body),
                  ZIO.mapError((e) => errMapper(e)),
                  ZIO.flatMap((a) =>
                    pipe(
                      response.decode(a.data),
                      E.mapLeft((e) => Throwable(e)),
                      E.map((a) => a),
                      ZIO.fromEither
                    )
                  )
                )
              )
            )

  export const putError =
    (config?: AxiosRequestConfig) =>
      <Body>(url: string) =>
        <C extends t.Mixed>(response: C, errMapper: ResponseError = baseError) =>
          (body?: Body) =>
            pipe(
              ZIO.zipPar(AuthService.token, AuthService.getLoginType, buildUrl(url)),
              ZIO.flatMap(([token, loginType, hostName]) =>
                pipe(
                  HttpClient.putAxios({
                    ...config,
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'X-Authen-Vendor': loginType
                    }
                  })(hostName)(body),
                  ZIO.mapError((e) => errMapper(e)),
                  ZIO.flatMap((a) =>
                    pipe(
                      response.decode(a.data),
                      E.mapLeft((e) => Throwable(e)),
                      E.map((a) => a),
                      ZIO.fromEither
                    )
                  )
                )
              )
            )

  export const delError =
    (config?: AxiosRequestConfig) =>
      (url: string) =>
        <C extends t.Mixed>(response: C, errMapper: ResponseError = baseError) =>
          pipe(
            ZIO.zipPar(AuthService.token, AuthService.getLoginType, buildUrl(url)),
            ZIO.flatMap(([token, loginType, hostName]) =>
              pipe(
                HttpClient.delAxios({
                  ...config,
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })(hostName),
                ZIO.mapError((e) => errMapper(e)),
                ZIO.flatMap((a) =>
                  pipe(
                    response.decode(a.data),
                    E.mapLeft((e) => Throwable(e)),
                    E.map((a) => a),
                    ZIO.fromEither
                  )
                )
              )
            )
          )
}
