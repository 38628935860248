import {
  NotoSans_400Regular,
  NotoSans_400Regular_Italic,
  NotoSans_700Bold,
  NotoSans_700Bold_Italic,
  useFonts
} from '@expo-google-fonts/noto-sans'
import { createTheme, SnackbarOrigin, ThemeProvider as MatThemeProvider } from '@material-ui/core'
import { Color } from '@material-ui/lab'
import { ZIO } from '@mxt/zio'
import * as R from '@mxt/zio-react'
import { useLoading } from '@mxt/zio-react'
import { EditHistoryModal as BusinessEditHistoryModal } from '@pulseops/business'
import {
  AlertDialog,
  AppConfig,
  AppContext,
  AuthService,
  AuthState,
  Breadcrumb,
  ErrorHandling,
  GlobalLoadingModal,
  Notice,
  RootNavigation,
  subscribe,
  theme,
  Toast,
  SnackBarDialog,
  ModalDialog,
  useIdleTimeout,
  RBAC
} from '@pulseops/common'
import { NavigationContainer, useLinkTo } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import React, { useEffect } from 'react'
import { ActivityIndicator, Platform, Text } from 'react-native'
import { ThemeProvider as ElementsThemeProvider } from 'react-native-elements'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ThemeProvider } from 'styled-components/native'
import { POLogging } from '../../common/src/POLogging'
import { ErrorScreen } from './ErrorScreen'
import { LandingPageStack } from './landing-page'
import { LandingPageCCCDStack } from './landing-page-cccd'
import { linking } from './linking'
import { LoginScreen } from './Login'
import { MainStackParamList } from './MainStackParamList'
import { RootStackParamList } from './RootStackParamList'
import { UserStack } from './user'
import { BusinessViewEmployeeOverFCLModal } from '@pulseops/business'
import { BusinessViewEmployeeModal } from '@pulseops/business'
import { InternalFeedbackStack } from './internal-feedback'
import { ZStream } from '@mxt/zio/stream'
import { useTranslation } from 'react-i18next'
import { OBInternalFeedbackStack } from '@pulseops/outbound'
import { LandingPageBankStack } from './landing-page-bank-info/LandingPageBankStack'
import { LandingPageIDCStack } from './landing-page-idc/LandingPageIDCStack'
import { useRoute, useNavigation } from '@react-navigation/native'
const logger = POLogging.getLogger('App')

export const injectWebCss = () => {
  // Only on web
  if (Platform.OS !== 'web') return

  // Inject style
  const style = document.createElement('style')
  style.textContent = `textarea, select, input, button { outline: none!important; }`
  return document.head.append(style)
}

const RootStack = createStackNavigator<RootStackParamList>()
const MainStack = createStackNavigator<MainStackParamList>()

const MainStackScreen = () => {
  const authData = pipe(AuthState.auth.watch, subscribe())
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const { t } = useTranslation()
  const linkTo = useLinkTo()

  pipe(
    ZIO.effectTotal(() => authData),
    ZIO.flatMap((authData) =>
      authData != null && authData.tag !== AuthState.Status.Unauthenticated
        ? AuthService.redirectInitialLink(linkTo)
        : ZIO.unit
    ),
    ErrorHandling.runDidUpdate([authData])
  )

  const {} = useIdleTimeout({
    onIdle: () => {
      if (authData?.tag === AuthState.Status.GA) {
        pipe(AuthService.logout, ZIO.unsafeRun({}))
      }
    },
    idleTime: 60 * 15
  })

  pipe(
    AuthService.singleSession,
    ZStream.chainEffect((valid) => {
      return valid
        ? ZIO.unit
        : ZIO.fromPromise(() =>
            pipe(
              ZIO.effect(() => {
                return showToast(t('message:GASingleSessionAccountMsg'), 'error')
              }),
              ZIO.flatMap((_) => AuthService.logout),
              ZIO.unsafeRun({})
            )
          )
    }),
    subscribe()
  )

  useEffect(() => {
    if (authData !== null && authData.tag !== AuthState.Status.Unauthenticated) {
      const checkPermission = () => {
        pipe(
          AuthService.userInfo,
          ZIO.flatMap((userInfo) => ZIO.effect(() => RBAC.permissions(userInfo.email)))
        )
      }
      checkPermission()
    }
  }, [window.location.href])

  return authData != null ? (
    <MainStack.Navigator screenOptions={{ headerShown: false }}>
      {authData.tag === AuthState.Status.Unauthenticated ? (
        // No token found, user isn't signed in
        <MainStack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={{
            // When logging out, a pop animation feels intuitive
            animationTypeForReplace: 'pop'
          }}
        />
      ) : (
        // User is signed in
        <>
          <MainStack.Screen name="UserStack" component={UserStack} />
          <MainStack.Screen name="OBInternalFeedbackStack" component={OBInternalFeedbackStack} />
          <MainStack.Screen name="InternalFeedbackStack" component={InternalFeedbackStack} />
        </>
      )}
      <MainStack.Screen name="LandingPageStack" component={LandingPageStack} />
      <MainStack.Screen name="LandingPageCCCDStack" component={LandingPageCCCDStack} />
      <MainStack.Screen name="LandingPageBankStack" component={LandingPageBankStack} />
      <MainStack.Screen name="LandingPageIDCStack" component={LandingPageIDCStack} />
    
    </MainStack.Navigator>
  ) : null
}

const matTheme = createTheme({
  palette: {
    primary: {
      main: '#ED1B2E'
    },
    secondary: {
      main: '#2196f3'
    }
  },
  props: {
    MuiPopover: {
      style: {
        zIndex: 13000
      }
    }
  }
})

export const App = () => {
  const [loading, bindLoading] = useLoading(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
    anchorOrigin?: SnackbarOrigin
    duration?: number
    canClose?: boolean
  }>({ open: false, message: '' })
  const [breadcrumbs, setBreadcrumbs] = React.useState<Breadcrumb[]>([])
  const [footer, setFooter] = React.useState<JSX.Element>(<></>)
  const [footerClaimActions, getFooterClaimActions] = React.useState<any[]>([])
  const [notices, setNotices] = React.useState<AppConfig.Notice[]>([])
  const [isHiddenTopMenu, setIsHiddenTopMenu] = React.useState(false)

  const showToast = (
    message: string,
    type?: Color,
    anchorOrigin?: SnackbarOrigin,
    duration?: number,
    canClose?: boolean
  ) => {
    setToast({ open: true, message, type: type, anchorOrigin: anchorOrigin, duration: duration, canClose: canClose })
  }

  const changeBreadcrumb = (newBreadcrumbs: Breadcrumb[]) => {
    setBreadcrumbs(newBreadcrumbs)
  }

  const getFooter = (newFooter: JSX.Element | null) => {
    setFooter(newFooter !== null ? newFooter : <></>)
  }

  const getFooterClaim = (newFooterClaimActions: any[]) => {
    getFooterClaimActions(newFooterClaimActions)
  }

  pipe(
    ZIO.zip(AuthService.init, ZIO.effectTotal(injectWebCss)),
    bindLoading,
    ZIO.catchAll((err) => logger.error('init error', err)),
    R.runDidMount()
  )

  pipe(
    AppConfig.getGlobalNotices,
    ZIO.catchAll((err) => logger.error('error', err)),
    ZIO.tap((notices) => {
      setNotices(notices || [])
      return ZIO.unit
    }),
    R.runDidMount()
  )

  const [fontsLoaded] = useFonts({
    NotoSans_400Regular,
    NotoSans_400Regular_Italic,
    NotoSans_700Bold,
    NotoSans_700Bold_Italic
  })

  const renderGlobalNotices = () => {
    return notices.map((item, index) => <Notice key={`global-notice-${index}`} data={item} />)
  }

  return (
    <ElementsThemeProvider
      theme={{
        colors: {
          primary: theme.colors.main
        }
      }}
    >
      <MatThemeProvider theme={matTheme}>
        <ThemeProvider theme={theme}>
          {renderGlobalNotices()}
          {loading || !fontsLoaded ? (
            <ActivityIndicator />
          ) : (
            <SafeAreaProvider>
              <AppContext.AppContextInstance.Provider
                value={{
                  isGlobalLoadingVisible: isLoading,
                  showGlobalLoading: setIsLoading,
                  showToast: showToast,
                  breadcrumbs: breadcrumbs,
                  changeBreadcrumb: changeBreadcrumb,
                  getFooter,
                  getFooterClaim,
                  footer,
                  footerClaimActions,
                  isHiddenTopMenu: isHiddenTopMenu,
                  setIsHiddenTopMenu: setIsHiddenTopMenu
                }}
              >
                <NavigationContainer
                  linking={linking}
                  fallback={<Text>Navigation loading...</Text>}
                  ref={RootNavigation.navigationRef}
                  onReady={RootNavigation.ready}
                  documentTitle={{
                    formatter: () => 'Pulse Ops'
                  }}
                >
                  <RootStack.Navigator mode="modal">
                    <RootStack.Screen name="MainStack" component={MainStackScreen} options={{ headerShown: false }} />
                    <RootStack.Screen name="ErrorScreen" component={ErrorScreen} />
                    <RootStack.Screen
                      name="BusinessEditHistoryModal"
                      component={BusinessEditHistoryModal}
                      options={{ headerShown: false }}
                    />
                    <RootStack.Screen
                      name="BusinessViewEmployeeOverFCLModal"
                      component={BusinessViewEmployeeOverFCLModal}
                      options={{ headerShown: false }}
                    />
                    <RootStack.Screen
                      name="BusinessViewEmployeeModal"
                      component={BusinessViewEmployeeModal}
                      options={{ headerShown: false }}
                    />
                  </RootStack.Navigator>
                </NavigationContainer>
                <Toast
                  visible={toast.open}
                  message={toast.message}
                  type={toast.type}
                  anchorOrigin={toast.anchorOrigin}
                  duration={toast.duration}
                  onClose={() => setToast({ open: false, message: '', type: undefined, anchorOrigin: undefined })}
                  canClose={toast.canClose}
                />
                <GlobalLoadingModal visible={isLoading} />
                <AlertDialog />
                <SnackBarDialog />
                <ModalDialog />
              </AppContext.AppContextInstance.Provider>
            </SafeAreaProvider>
          )}
        </ThemeProvider>
      </MatThemeProvider>
    </ElementsThemeProvider>
  )
}
