import { ZIO } from '@mxt/zio'
import { RefLocalStorage } from '@mxt/zio-ref-local-storage'
import { flow, pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { DateFromISOString, option } from 'io-ts-types'
import * as O from 'fp-ts/Option'
import { Maybe } from '@mxt/zio/codec'

export namespace AuthState {
  export enum Status {
    Unauthenticated = 'Unauthenticated',
    GA = 'GA',
    Staff = 'Staff'
  }

  const UnauthenticatedC = t.type({
    tag: t.literal(Status.Unauthenticated)
  })
  type Unauthenticated = t.TypeOf<typeof UnauthenticatedC>
  const Unauthenticated: Unauthenticated = {
    tag: Status.Unauthenticated
  }

  const GALoginC = t.type({
    tag: t.literal(Status.GA),
    token: t.type({
      access_token: t.string,
      expires_at: DateFromISOString
    })
  })
  type GALogin = t.TypeOf<typeof GALoginC>
  const GALogin = (token: { access_token: string; expires_at: Date }): GALogin => ({
    tag: Status.GA,
    token
  })

  const StaffLoginC = t.type({
    tag: t.literal(Status.Staff),
    username: t.string,
    access_token: Maybe(t.string),
    redirectUrl: Maybe(t.string)
  })
  type StaffLogin = t.TypeOf<typeof StaffLoginC>
  const StaffLogin = (username: string, access_token: string, redirectUrl: string): StaffLogin => ({
    tag: Status.Staff,
    username,
    access_token,
    redirectUrl
  })

  const AuthDataC = t.union([UnauthenticatedC, GALoginC, StaffLoginC])
  type AuthData = t.TypeOf<typeof AuthDataC>

  const stateRef = RefLocalStorage.make(
    t.type(
      {
        authData: AuthDataC,
        redirectUrl: option(t.string)
      },
      'AuthState'
    ),
    {
      authData: { tag: Status.Unauthenticated },
      redirectUrl: O.none
    }
  )

  export const auth = {
    get: stateRef.select((s) => s.authData),
    watch: stateRef.watch((s) => s.authData)
  }
  const setAuth = (authData: AuthData) =>
    pipe(
      stateRef.update((s) => ({
        ...s,
        authData
      })),
      ZIO.asVoid
    )

  const setInitialAuth = (authData: AuthData, url: string) =>
    pipe(
      stateRef.update((s) => ({
        ...s,
        authData,
        redirectUrl: O.some(url)
      })),
      ZIO.asVoid
    )

  export const setUnauthenticatedInitial = (url: string) => setInitialAuth(Unauthenticated, url)
  export const setUnauthenticated = setAuth(Unauthenticated)
  export const setStaffLogin = flow(StaffLogin, setAuth)
  export const setGALogin = flow(GALogin, setAuth)

  export const redirectUrl = {
    get: stateRef.select((s) => s.redirectUrl),
    set: (url: string) =>
      pipe(
        stateRef.update((s) => ({
          ...s,
          redirectUrl: O.some(url)
        })),
        ZIO.asVoid
      ),
    remove: pipe(
      stateRef.update((s) => ({
        ...s,
        redirectUrl: O.none
      })),
      ZIO.asVoid
    )
  }
}
