import { convertUnicodeToViqr, form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import { BenIDCard } from './ben-const'

export namespace BenificiaryDesignationForm {
  // const Selection = t.type({
  //   label: form2.string.required,
  //   value: form2.string.required
  // })

  type EmailBrand = {
    readonly Email: unique symbol
  }

  type Email = t.Branded<NonEmptyString, EmailBrand>

  const Email = pipe(
    withMessage(NonEmptyString, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Email').toLowerCase() })
    ),
    form2.refine(
      (l): l is Email =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          l
        ),
      (l) => i18next.t('message:MS050013', { field: i18next.t('submission:Email') }),
      'Email'
    )
  )

  type IdNumberBrand = {
    readonly IdNumber: unique symbol
  }

  type IdNumber = t.Branded<NonEmptyString, IdNumberBrand>
  const IdNumber = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:BENIDNumber') })),
    form2.refine(
      (l): l is IdNumber => l.length >= 2 && l.length <= 24,
      (l) => i18next.t('message:MS050216'),
      'IdNumber'
    )
  )

  type NumberStringBrand = {
    readonly NumberString: unique symbol
  }

  type NumberString = t.Branded<NonEmptyString, NumberStringBrand>

  const PhoneNumber = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone') })),
    form2.refine(
      (l): l is NumberString => /\b\d{10}\b/.test(l),
      (l) => i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') }),
      'NumberString'
    )
  )

  type BenefitShareBrand = {
    readonly BenefitShare: unique symbol
  }

  type ValidStreetBrand = {
    readonly ValidStreet: unique symbol
    readonly MS050047: unique symbol
  }

  type BenefitShare = t.Branded<NonEmptyString, BenefitShareBrand>
  type ValidStreet = t.Branded<NonEmptyString, ValidStreetBrand>

  const invalidStreet = (text: string): boolean => {
    return /[");']/.test(text) || /-{2}/.test(text) || /Xp_/.test(text) || /\/\*.+\*\//.test(text)
  }

  const BenefitShare = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:BenefitShare') })),
    form2.refine(
      (l): l is BenefitShare => /^[0-9]+$/.test(l) && Number.isInteger(Number(l)) && Number(l) <= 100 && Number(l) > 0,
      (l) => i18next.t('submission:Input1to100'),
      'BenefitShare'
    )
  )

  // const Beneficiary = t.type({
  //   idType: withMessage(form2.selectOption.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:idType') })
  //   ),
  //   idNumber: IdNumber,
  //   issueBy: withMessage(form2.selectOption.optional, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:IssueBy') })
  //   ),
  //   relationship: withMessage(form2.selectOption.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:Relationship') })
  //   ),
  //   surname: withMessage(form2.string.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:Surname').toLowerCase() })
  //   ),
  //   givenName: withMessage(form2.string.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:GivenName').toLowerCase() })
  //   ),

  //   dob: withMessage(form2.date.requiredMax(new Date()), () => i18next.t('message:MS050022')),
  //   gender: withMessage(form2.selectOption.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:Gender').toLowerCase() })
  //   ),
  //   nationality: withMessage(form2.selectOption.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:Nationality').toLowerCase() })
  //   ),
  //   mobileCode: withMessage(form2.selectOption.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:PhoneNumber').toLowerCase() })
  //   ),
  //   mobileNumber: form2.optional(PhoneNumber),
  //   email: form2.optional(Email),
  //   benefitShare: BenefitShare,
  //   clientID: form2.string.optional,
  //   enable: t.boolean
  // })

  const Beneficiary = t.intersection([
    t.type({
      //enable: t.boolean,
      clientID: form2.string.optional,
      benefitShare: BenefitShare
    }),
    t.union([
      t.intersection([
        t.type({
          enable: t.literal(true),
          idType: withMessage(form2.selectOption.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:idType') })
          ),
          idNumber: IdNumber,
          requireIssueBy: t.boolean,
          relationship: withMessage(form2.selectOption.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:Relationship') })
          ),
          surname: withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:Surname').toLowerCase() })
          ),
          givenName: withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:GivenName').toLowerCase() })
          ),

          dob: withMessage(form2.date.requiredMax(new Date()), () => i18next.t('message:MS050022')),
          gender: withMessage(form2.selectOption.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:Gender').toLowerCase() })
          ),
          nationality: withMessage(form2.selectOption.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:Nationality').toLowerCase() })
          ),
          mobileCode: withMessage(form2.selectOption.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:PhoneNumber').toLowerCase() })
          ),
          mobileNumber: form2.optional(PhoneNumber),
          email: form2.optional(Email),
          occupation: form2.selectOption.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('submission:Occupation') })),
          country: form2.selectOption.optional,
          city: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('requestInfo:City') })),
          district: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('requestInfo:District') })),
          ward: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('requestInfo:Ward') })),
          street: pipe(
            withMessage(form2.string.required, () => i18next.t('message:MS020001', { field: i18next.t('submission:Street') })),
            form2.refine(
              (data): data is ValidStreet =>
                (!invalidStreet(data) && data.match(new RegExp(/[!@#$%^&*"]/g)) === null),
              () => i18next.t('message:MS050223'),
              'ValidStreet'
            )
          ),
          // countryOfForeignAddress: form2.selectOption.optional,
          nationality2: form2.selectOption.optional
        }),
        t.union([
          t.type({
            requireIssueBy: t.literal(false),
            issueBy: form2.selectOption.optional
          }),
          t.type({
            requireIssueBy: t.literal(true),
            issueBy: withMessage(form2.selectOption.required, () =>
              i18next.t('message:MS020001', { field: i18next.t('submission:IssueBy') })
            )
          })
        ])
      ]),
      t.type({
        enable: t.literal(false),
        idType: withMessage(form2.selectOption.optional, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:idType') })
        ),
        idNumber: form2.optional(IdNumber),
        requireIssueBy: t.boolean,
        issueBy: withMessage(form2.selectOption.optional, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:IssueBy') })
        ),
        relationship: withMessage(form2.selectOption.optional, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Relationship') })
        ),
        surname: withMessage(form2.string.optional, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Surname').toLowerCase() })
        ),
        givenName: withMessage(form2.string.optional, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:GivenName').toLowerCase() })
        ),
        dob: form2.date.optional,
        gender: withMessage(form2.selectOption.optional, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Gender').toLowerCase() })
        ),
        nationality: withMessage(form2.selectOption.optional, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Nationality').toLowerCase() })
        ),
        mobileCode: withMessage(form2.selectOption.optional, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:PhoneNumber').toLowerCase() })
        ),
        mobileNumber: form2.optional(PhoneNumber),
        email: form2.optional(Email),
        occupation: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('submission:Occupation') })),
        country: form2.selectOption.optional,
        city: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('requestInfo:City') })),
        district: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('requestInfo:District') })),
        ward: withMessage(form2.selectOption.required, () => i18next.t('message:MS020009', { field: i18next.t('requestInfo:Ward') })),
        street: pipe(
          withMessage(form2.string.required, () => i18next.t('message:MS020001', { field: i18next.t('submission:Street') })),
          form2.refine(
            (data): data is ValidStreet =>
              (!invalidStreet(data) && data.match(new RegExp(/[!@#$%^&*"<>|\\]/g)) === null),
            () => i18next.t('message:MS050223'),
            'ValidStreet'
          )
        ),
        // countryOfForeignAddress: form2.selectOption.required,
        nationality2: form2.selectOption.optional
      })
    ]),
    t.union([
      t.type({
        foreignAddressRequired: t.literal(true),
        foreignAddress: pipe(
          form2.string.requiredM(() =>
            i18next.t('message:MS020001', {
              field: i18next.t('submission:ForeignAddress')
            })
          ),
          form2.refine(
            (data): data is ValidStreet => !invalidStreet(data) && data.match(new RegExp(/[!@#$%^&*"<>|\\]/g)) === null,
            () => i18next.t('message:MS050223'),
            'ValidStreet'
          ),
          form2.refine(
            (l): l is ValidStreet => convertUnicodeToViqr(l).length <= 30,
            () => i18next.t('message:MS050047'),
            'MS050047'
          )
        ),
        countryOfForeignAddress: form2.selectOption.requiredM(() =>
          i18next.t('message:MS020009', {
            field: i18next.t('submission:CountryOfForeignAddress')
          })
        )
      }),
      t.type({
        foreignAddressRequired: t.literal(false),
        foreignAddress: pipe(
          form2.string.optional,
          form2.refine(
            (data): data is ValidStreet =>
              !data || (!invalidStreet(data) && data.match(new RegExp(/[!@#$%^&*"<>|\\]/g)) === null),
            () => i18next.t('message:MS050223'),
            'ValidStreet'
          ),
          form2.refine(
            (l): l is ValidStreet => !l || convertUnicodeToViqr(l).length <= 30,
            () => i18next.t('message:MS050047'),
            'MS050047'
          )
        ),
        countryOfForeignAddress: form2.selectOption.optional,
      })
    ]),
  ])

  export type Beneficiary = t.TypeOf<typeof Beneficiary>

  export type BeneficiaryRaw = t.OutputOf<typeof Beneficiary>
  type BeneficiaryBrand = {
    readonly BeneficiaryIssueByCheck: unique symbol
  }

  type BeneficiaryIssueByCheck = t.Branded<Beneficiary, BeneficiaryBrand>

  const BeneficiaryIssueByCheck = pipe(
    Beneficiary,
    form2.refine(
      (l): l is BeneficiaryIssueByCheck => {
        console.log('checkeckekckeckekce', l)
        if (l.idType && l.idType.value === BenIDCard.BIRTH_CERTIFICATE) {
          return l.issueBy !== undefined && l.issueBy !== null
        }
        return true
      },
      (l) => i18next.t('message:MS020001', { field: i18next.t('submission:IssueBy') }),
      'BeneficiaryIssueByCheck'
    )
  )

  type ListBeneficiaryBrand = {
    readonly ListBeneficiary: unique symbol
  }

  type ListBeneficiary = t.Branded<Beneficiary[], ListBeneficiaryBrand>

  const ListBeneficiary = pipe(
    t.array(Beneficiary),
    form2.refine(
      (l): l is ListBeneficiary => l.length > 0,
      (l) => 'Error',
      'ListBeneficiary'
    )
  )

  const FileMeta = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type FileMeta = t.TypeOf<typeof FileMeta>

  type ListFileBrand = {
    readonly ListFile: unique symbol
  }

  type ListFile = t.Branded<FileMeta[], ListFileBrand>

  const ListFile = pipe(
    t.array(FileMeta),
    form2.refine(
      (l): l is ListFile => l.length >= 1,
      (l) => i18next.t('message:MS020001', { field: i18next.t('utilities:FileAttachment').toLowerCase() }),
      'ListFile'
    )
  )

  export const codec = t.type({
    beneficiaries: ListBeneficiary,
    fileAttachments: form2.optional(t.array(FileMeta))
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
