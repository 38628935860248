import { CustomerRoleEnum } from './GeneralInfoService'
import { BenIDCard } from './model'

export const mapChangeClientInfoRelationship = new Map<string, string>([
  ['AU', 'CCI.relationship.au'],
  ['BU', 'CCI.relationship.bu'],
  ['CH', 'CCI.relationship.ch'],
  ['CO', 'CCI.relationship.co'],
  ['EE', 'CCI.relationship.ee'],
  ['ER', 'CCI.relationship.er'],
  ['GC', 'CCI.relationship.gc'],
  ['GP', 'CCI.relationship.gp'],
  ['GU', 'CCI.relationship.gu'],
  ['NE', 'CCI.relationship.ne'],
  ['PA', 'CCI.relationship.pa'],
  ['SI', 'CCI.relationship.si'],
  ['SP', 'CCI.relationship.sp']
])

export const translatorMap = new Map<string, string>([
  [BenIDCard.NATIONAL_ID, `submission:ID`],
  [BenIDCard.PASSPORT, `contact:Passport`],
  [BenIDCard.BIRTH_CERTIFICATE, `contact:BirthCertificate`],
  [CustomerRoleEnum.OWNER, `roles:PolicyOwner`],
  [CustomerRoleEnum.MAIN_LA, `roles:MainLifeAssured`],
  [CustomerRoleEnum.LA, `roles:LifeAssured`],
  [CustomerRoleEnum.BEN, `roles:Beneficiary`],
  ['CCI.Salary.00', `salary:NotDeclared`],
  ['CCI.Salary.01', `salary:Below 2.5 mil`],
  ['CCI.Salary.02', `salary:From 2.5 mil to below 4.5 mil`],
  ['CCI.Salary.03', `salary:From 4.5 mil to below 7.5 mil`],
  ['CCI.Salary.04', `salary:From 7.5 mil to below 15 mil`],
  ['CCI.Salary.05', `salary:From 15 mil and above`],
  ['CCI.relationship.au', `relationship:Aunt/Uncle/Mother's sister`],
  ['CCI.relationship.bu', `relationship:Business relationship`],
  ['CCI.relationship.ch', `relationship:Children`],
  ['CCI.relationship.co', `relationship:Corp`],
  ['CCI.relationship.ee', `relationship:Employee`],
  ['CCI.relationship.er', `relationship:Corp Owner`],
  ['CCI.relationship.gc', `relationship:Grandchildren`],
  ['CCI.relationship.gp', `relationship:Grand Parents`],
  ['CCI.relationship.gu', `relationship:Legal Guardian`],
  ['CCI.relationship.ne', `relationship:Nephew`],
  ['CCI.relationship.pa', `relationship:Parents`],
  ['CCI.relationship.si', `relationship:Sister/ Brother`],
  ['CCI.relationship.sp', `relationship:Spouse`]
])

export const translator = (val: string): string => translatorMap.get(val) ?? val
