import { AppContext, ErrorHandling, GeneralService, Panel, SourceType, TaskDetail, translator } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { TFunctionResult } from 'i18next'
import { NewBeneficiaryInfo } from 'libs/common/src/service/model/task-detail'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import { InfoViewList, Title } from '../common'
import { isNil } from 'lodash'
import { ZIO } from '@mxt/zio'
import * as O from 'fp-ts/lib/Option'

type Output = {
  label: string
  value: string | number | JSX.Element | TFunctionResult
  suffix?: string
  isHighlight?: boolean
}

type BeneficiaryList = {
  title: JSX.Element
  bens: Output[]
}

export const BeneficiaryDesignation = ({
  detail,
  isSeaBankShowedMessage
}: {
  detail: TaskDetail.ChangeBeneficialInfo
  isSeaBankShowedMessage: boolean
}) => {
  const { t, i18n } = useTranslation(['requestInfo', 'common'])
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const { relationshipT16s1, occupations } = pipe(
    ZIO.zipPar(GeneralService.getRelationship(), GeneralService.getOccupations),
    ZIO.map(([relationshipT16s1, occupations]) => {
      return {
        relationshipT16s1,
        occupations
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    relationshipT16s1: [],
    occupations: []
  }
  const isShowSignatureRegistration =
    detail && detail.sourceType === SourceType.ZALO && isNil(detail.newBen[0].existAttachments)
      ? false
      : detail.newBen[0].existAttachments ?? false

  const getRelationship = (code: string): string => {
    const r = relationshipT16s1.find((r) => r.code === code)
    return r ? (i18n.language === 'en' ? r.nameEn ?? '-' : r.nameVn) : code || '-'
  }

  const gender = (value: string) =>
    value === 'F' ? `${t('common:Female')}` : value === 'M' ? `${t('common:Male')}` : value

  const getBenFields = (info: NewBeneficiaryInfo): Output[] => {
    const occupationName = pipe(
      occupations.find((item) => item.code === info.occupation),
      O.fromNullable,
      O.map((item) => item.name),
      O.getOrElse(() => '-')
    )
    let benArr = [
      { label: `${t('IdType')}`, value: t(`${translator(info?.documentType)}`) || '-' },
      { label: `${t('submission:BENIDNumber')}`, value: info?.nationalID || '-' },
      { label: `${t('IssuedBy')}`, value: info.issuedBy },
      {
        label: `${t('Bens.Relationship')}`,
        value: getRelationship(info.relationshipWithLifeAssured)
      },
      { label: `${t('Surname')}`, value: info.surname || '-' },
      { label: `${t('GivenName')}`, value: info.givenName || '-' },
      { label: `${t('DOB')}`, value: info.dob || '-' },
      { label: `${t('Gender')}`, value: gender(info.gender) },
      { label: `${t('Nationality')}`, value: info.nationality || '-' },
      { label: `${t('MobilePhone')}`, value: info.mobile?.number || '-' },
      { label: `${t('Email')}`, value: info.email || '-' },
      { label: `${t('BenefitShare')}` + ' (%)', value: info?.percentage?.toString() || '-' }
    ]

    if (!!info.occupation && !!info.city && !!info.district) {
      benArr = [
        ...benArr,
        { label: t('submission:Occupation'), value: occupationName },
        { label: t('submission:Country'), value: info.countryName || '-' },
        { label: t('submission:CityProvince'), value: info.city || '-' },
        { label: t('submission:District'), value: info.districtName || '-' },
        { label: t('submission:WardSubDistrict'), value: info.wardName || '-' },
        { label: t('submission:Street'), value: info.address || '-' },
        { label: t('submission:Nationality2'), value: info.nationality2 || '-' },
        { label: t('submission:ForeignAddress'), value: info.foreignAddress || '-' },
        { label: t('submission:CountryOfForeignAddress'), value: info.foreignCountry || '-' }
      ]
    }

    return benArr
  }

  React.useEffect(() => {
    if (detail.sourceType === SourceType.PULSE4OPS && !!detail.seaBankWarningMsg) {
      showToast(detail.seaBankWarningMsg, 'warning', undefined, 30000)
    }
  }, [detail])

  const getTitle = (ben: NewBeneficiaryInfo): JSX.Element => {
    const title = (
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{ben.surname + ' ' + ben.givenName}</Text>
        {ben.percentage && (
          <View style={styles.bagde}>
            <Text style={styles.bagdeText}>{ben.percentage + '%'}</Text>
          </View>
        )}
      </View>
    )

    return title
  }

  const getBenList = (): BeneficiaryList[] => {
    return detail?.newBen.map((ben: NewBeneficiaryInfo) => ({
      bens: getBenFields(ben),
      title: getTitle(ben)
    }))
  }

  return (
    <View>
      {isShowSignatureRegistration ? (
        <Text style={styles.text}>{t('message:MS050272')}</Text>
      ) : (
        <>
          <Title title={t('BeneficiaryDesignation')} />
          {getBenList()?.map((benList: BeneficiaryList, index) => (
            <Panel key={index} title={benList?.title}>
              <InfoViewList dataSource={benList.bens} />
            </Panel>
          ))}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    marginRight: 16
  },
  bagde: {
    backgroundColor: '#1EA5FC',
    padding: 3,
    borderRadius: 5
  },
  bagdeText: {
    fontSize: 12,
    color: '#FFFFFF'
  },
  text: {
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 16
  }
})
