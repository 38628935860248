import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { AuthService, InternalFeedbackService, POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export type DataSuspend = {
  suspendCode: string,
  status: string,
  user: string,
  slaHour: string,
  effectiveDate: string,
  dueDate: string,
  department: string,
  level: string,
  content: string,
  emailTo: string,
  emailCc: string,
  timeZone: string
}

export type DataSuspendSave = {
  data: DataSuspend[]
  processInstanceId: string
}

export type DataSuspendHistory = {
  id: string,
  suspendCode: string,
  status: string,
  user: string,
  slaHour: string,
  effectiveDate: string,
  dueDate: string,
  department: string,
  level: string,
  content: string,
  emailTo: string,
  emailCc: string,
  timeZone: string
}

export type DataSuspendHistorySave = {
  data: DataSuspendHistory[]
  processInstanceId: string
}

export const OBInternalSuspendData = t.type({
  id: Maybe(t.string),
  suspendGroupCode: Maybe(t.string),
  suspendGroupEN: Maybe(t.string),
  suspendGroupVN: Maybe(t.string),
  suspendCode: Maybe(t.string),
  suspendDescEN: Maybe(t.string),
  suspendDescVn: Maybe(t.string),
  status: Maybe(t.string),
  user: Maybe(t.string),
  slaHour: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  dueDate: Maybe(t.string),
  department: Maybe(t.string),
  level: Maybe(t.string),
  content: Maybe(t.string),
  emailTo: Maybe(t.string),
  emailCc: Maybe(t.string),
  createdBy: Maybe(t.string),
  createdDate: Maybe(t.string),
  updatedBy: Maybe(t.string),
  updatedDate: Maybe(t.string),
  timeZone: Maybe(t.string)
})
export type OBInternalSuspendData = t.TypeOf<typeof OBInternalSuspendData>

export const OBSuspendCodeData = t.type({
  code: t.string,
  typeEn: t.string,
  typeVn: t.string,
  description: Maybe(t.array(t.string)),
  timeout: Maybe(t.string),
  departmentCode: Maybe(t.string),
  department: Maybe(t.string),
  departmentShortName: Maybe(t.string),
  departmentVN: Maybe(t.string)
})
export type OBSuspendCodeData = t.TypeOf<typeof OBSuspendCodeData>

export const OBInternalRulesListData = t.type({
  // suspendCode: Maybe(t.string),
  normalEmailTo: Maybe(t.string),
  normalEmailCc: Maybe(t.string),
  urgentEmailTo: Maybe(t.string),
  urgentEmailCc: Maybe(t.string),
  criticalEmailTo: Maybe(t.string),
  criticalEmailCc: Maybe(t.string),
  slaHourNormal: Maybe(t.string),
  slaHourUrgent: Maybe(t.string),
  slaHourCritical: Maybe(t.string),
  department: Maybe(t.string),
  departmentShortName: Maybe(t.string)
})
export type OBInternalRulesListData = t.TypeOf<typeof OBInternalRulesListData>

export const OBInternalSuspendHistoryData = t.type({
  id: Maybe(t.string),
  suspendGroupCode: Maybe(t.string),
  suspendGroupEN: Maybe(t.string),
  suspendGroupVN: Maybe(t.string),
  suspendCode: Maybe(t.string),
  suspendType: Maybe(t.string),
  suspendTypeVN: Maybe(t.string),
  slaHour: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  dueDate: Maybe(t.string),
  status: t.string,
  department: Maybe(t.string),
  level: Maybe(t.string),
  content: Maybe(t.string),
  emailTo: Maybe(t.string),
  emailCc: Maybe(t.string),
  createdBy: Maybe(t.string),
  createdDate: t.string,
  modifiedBy: Maybe(t.string),
  modifiedDate: Maybe(t.string)
})
export type OBInternalSuspendHistoryData = t.TypeOf<typeof OBInternalSuspendHistoryData>

export const OBSubmitedInternalFeedback = t.type({
  data: t.type({
    parentId: t.string,
    feedbackDepartment: t.string,
    feedbackDetail: t.string,
    feedbackUser: t.string
  }),
  processInstanceId: t.string
})
export type OBSubmitedInternalFeedback = t.TypeOf<typeof OBSubmitedInternalFeedback>

export const OBResponsedInternalData = t.type({
  data: Maybe(
    t.type({
      id: Maybe(t.string),
      parentId: Maybe(t.string),
      feedbackDepartment: Maybe(t.string),
      feedbackDetail: Maybe(t.string),
      feedbackUser: Maybe(t.string)
    })
  ),
  responseError: t.type({
    code: t.string,
    status: t.string,
    message: t.string
  })
})

export const OBMailList = Maybe(t.array(
  t.type({
    mailGroup: Maybe(t.string),
    emails: Maybe(t.array(t.string)),
    department: t.string,
    departmentCode: t.string
  }))
)
export type OBMailList = t.TypeOf<typeof OBMailList>

export const OBResponseInternalSuspendData = t.type({
  id: t.string,
  suspendGroupCode: t.string,
  suspendGroupEN: t.string,
  suspendGroupVN: t.string,
  suspendCode: t.string,
  suspendType: t.string,
  suspendTypeVN: t.string,
  slaHour: Maybe(t.string),
  effectiveDate: t.string,
  dueDate: t.string,
  status: t.string,
  department: Maybe(t.string),
  level: Maybe(t.string),
  content: Maybe(t.string),
  emailTo: Maybe(t.string),
  emailCc: Maybe(t.string),
  createdBy: t.string,
  createdDate: t.string,
  modifiedBy: Maybe(t.string),
  modifiedDate: Maybe(t.string),
  caseId: Maybe(t.string),
  internalFeedback: Maybe(t.type({
    id: t.string,
    parentId: t.string,
    feedbackDepartment: Maybe(t.string),
    feedbackDate: t.string,
    feedbackDetail: t.string,
    feedbackUser: t.string,
    feedbackDepartmentEN: Maybe(t.string),
    feedbackDepartmentVN: Maybe(t.string)
  }))
})
const OBResponseInternalSuspendDataList = Maybe(t.array(OBResponseInternalSuspendData))
export type OBResponseInternalSuspendDataList = t.TypeOf<typeof OBResponseInternalSuspendDataList>

export const OBInternalHistoryResponse = t.type({
  data: t.array(OBResponseInternalSuspendData),
  total: t.number,
  page: t.number,
  size: t.number
})

export const T6S2EscalationRuleResponse = t.type({
  categoryCode: t.string,
  suspendCode: t.string,
  normalEmailTo: t.string,
  normalEmailCc: t.string,
  urgentEmailTo: t.string,
  urgentEmailCc: t.string,
  criticalEmailTo: t.string,
  criticalEmailCc: t.string,
  slaHourNormal: t.string,
  slaHourUrgent: t.string,
  slaHourCritical: t.string,
  department: Maybe(t.string),
  departmentShortName: t.string,
  departmentCode: t.string
})

export const ResponseInternalSuspendData = t.type({
  id: t.string,
  suspendGroupCode: t.string,
  suspendGroupEN: t.string,
  suspendGroupVN: t.string,
  suspendCode: t.string,
  suspendType: t.string,
  suspendTypeVN: t.string,
  slaHour: t.string,
  effectiveDate: t.string,
  dueDate: Maybe(t.string),
  status: t.string,
  department: t.string,
  level: t.string,
  content: t.string,
  emailTo: Maybe(t.string),
  emailCc: Maybe(t.string),
  createdBy: t.string,
  createdDate: t.string,
  modifiedBy: Maybe(t.string),
  modifiedDate: Maybe(t.string),
  caseId: Maybe(t.string)
})

export const NewInternalFeedbackDetail = t.type({
  id: t.string,
  parentId: t.string,
  feedbackDepartment: Maybe(t.string),
  feedbackDate: t.string,
  feedbackDetail: t.string,
  feedbackUser: t.string,
  feedbackDepartmentEN: Maybe(t.string),
  feedbackDepartmentVN: Maybe(t.string)
})

export const PreviousFeedbackData = t.union([NewInternalFeedbackDetail, t.type({}), t.null, t.undefined])

export const NewSavedInternalFeedbackRequest = t.type({
  suspendID: t.string,
  policyNum: t.string,
  feedbackUser: t.string,
  feedbackDepartment: t.string,
  feedbackDetail: t.string,
  categoryCode: t.string
})

export const NewSuspendHistoryRequest = t.type({
  policyNum: t.string,
  suspendID: t.string,
  page: t.string,
  size: t.string,
  sortField: t.string,
  sort: t.string,
  department: t.string
})

export type NewSuspendHistoryRequest = t.TypeOf<typeof NewSuspendHistoryRequest>
export type NewSavedInternalFeedbackRequest = t.TypeOf<typeof NewSavedInternalFeedbackRequest>
export type PreviousFeedbackData = t.TypeOf<typeof PreviousFeedbackData>
export type ResponseInternalSuspendData = t.TypeOf<typeof ResponseInternalSuspendData>
export type OBInternalHistoryResponse = t.TypeOf<typeof OBInternalHistoryResponse>
export type T6S2EscalationRuleResponse = t.OutputOf<typeof T6S2EscalationRuleResponse>
export type NewInternalFeedbackDetail = t.OutputOf<typeof NewInternalFeedbackDetail>
export namespace OBInternalSuspendService {
  export const saveInternalSuspend = (dataSuspend: DataSuspendSave) => pipe(
    POApi.put(`pulseops/api/v1/outbound-suspend/internal/save`)(t.type({
      data: t.array(OBInternalSuspendData),
      responseError: t.type({
        code: Maybe(t.string),
        message: Maybe(t.string),
        status: Maybe(t.string)
      })
    }))(dataSuspend),
    ZIO.map((res) => res)
  )

  export const getSuspendTypeList = (processInstanceId: string) => pipe(
    POApi.get(`pulseops/api/v1/outbound-suspend/internal/suspend-code`, { params: { processInstanceId: processInstanceId } })(t.array(OBSuspendCodeData)),
    ZIO.map((res) => res)
  )

  export const getInternalRulesListData = () => pipe(
    POApi.get(`pulseops/api/v1/outbound-suspend/email-rule`)(t.type({
      data: Maybe(t.array(OBInternalRulesListData)),
    })),
    ZIO.map((res) => res)
  )

  export const updatePlanSuspendDate = (processInstanceId: string, unsuspendDate: string, createdBy: string) => pipe(
    POApi.post(`pulseops/api/v1/outbound-suspend/unsuspend-date`)(t.type({
      responseError: t.type({
        code: t.string,
        status: t.string,
        message: t.string
      }),
      processInstanceId: Maybe(t.string),
      unsuspendDate: Maybe(t.string)
    }))({
      processInstanceId: processInstanceId,
      unsuspendDate: unsuspendDate,
      createdBy: createdBy
    }),
    ZIO.map((res) => res)
  )

  export const updatedInternalHistory = (dataSuspend: DataSuspendHistorySave) => pipe(
    POApi.put(`pulseops/api/v1/outbound-suspend/internal/update-list-suspend`)(t.type({
      data: Maybe(t.array(OBInternalSuspendHistoryData)),
      responseError: t.type({
        code: t.string,
        status: t.string,
        message: t.string
      })
    }))(dataSuspend)
  )

  export const saveInternalSuspendFeedback = (submitedData: OBSubmitedInternalFeedback) => pipe(
    POApi.put(`pulseops/api/v1/outbound-suspend/save-feedback`)(OBResponsedInternalData)({
      data: submitedData.data,
      processInstanceId: submitedData.processInstanceId
    }),
    ZIO.map((responseData) => {
      return responseData
    })
  )

  export const getMailList = (departmentCode: string) => pipe(
    POApi.get(`pulseops/api/v1/t27/get-detail-by-params?departmentCode=${departmentCode}`)(
      t.type({
        data: OBMailList
      })
    ),
    ZIO.map((responseData) => {
      const toEmailList = responseData.data && responseData.data.length > 0 ? responseData.data : []
      return toEmailList
    })
  )

  export const getCaseStatusOBByCaseID = (caseID: string) => pipe(
    POApi.post(`pulseops/api/v1/internal-feedback/get-case-status`)(t.type({
      data: Maybe(
        t.array(t.type({
          status: t.string,
          processInstanceId: Maybe(t.string)
        }))
      )
    }))({
      businessKey: caseID,
      category: 'OB'
    }),
    ZIO.map((response) => {
      const status = !!response.data && response.data.length > 0 ? (response.data[0].status ?? '') : ''
      return status
    })
  )

  export const getInternalSuspendHistoryListOB = (processInstanceId: string) => {
    return pipe(
      POApi.get(`pulseops/api/v1/internal-feedback/get-history-list?processInstanceId=${processInstanceId}`)(
        t.type({
          data: OBResponseInternalSuspendDataList
        })
      ),
      ZIO.map((responseData) => {
        return responseData.data
      })
    )
  }

  export const getInternalHistoryListOB = (processInstanceId: string, start: number, size: number, departmentCode?: string) =>
    pipe(
      POApi.get(
        `pulseops/api/v1/internal-feedback/get-histories?processInstanceId=${processInstanceId}&department=${departmentCode}&start=${start}&size=${size}`
      )(OBInternalHistoryResponse)
    )

  export const getInternalSuspendDetailOB = (casid: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/internal-feedback/get-detail-suspend/${casid}`)(OBResponseInternalSuspendData),
      ZIO.map((responseData) => {
        return responseData
      })
    )
  export const getT6S2EscalationRule = (suspendCode: string) => pipe(
    POApi.get(`pulseops/api/v1/outbound-suspend/email-rule?suspendCode=${suspendCode}`)(t.type({
      data: Maybe(t.array(T6S2EscalationRuleResponse))
    })),
    ZIO.map((responseData) => {
      return responseData.data
    })
  )


  export const getNewSuspendHistoryList = (inputData: NewSuspendHistoryRequest) => pipe(
    AuthService.getInternalCachedToken(),
    ZIO.flatMap((token) => POApi.internalAuthorizedPost(`feedback-rest-internal/api/v1/internal-feedback/suspend-histories`, { headers: { Authorization: `Bearer ${token}` } })(t.type({
      data: t.type({
        list: t.array(t.type({
          id: t.string,
          suspendGroupCode: t.string,
          suspendCode: t.string,
          suspendGroupEN: Maybe(t.string),
          suspendGroupVN: Maybe(t.string),
          suspendType: Maybe(t.string),
          suspendTypeVN: Maybe(t.string),
          slaHour: t.string,
          effectiveDate: t.string,
          dueDate: Maybe(t.string),
          status: t.string,
          department: t.string,
          departmentShortName: t.string,
          departmentEN: t.string,
          departmentVN: t.string,
          level: t.string,
          content: t.string,
          emailTo: Maybe(t.string),
          emailCc: Maybe(t.string),
          createdBy: t.string,
          createdDate: t.string,
          modifiedBy: Maybe(t.string),
          modifiedDate: Maybe(t.string),
          caseId: Maybe(t.string),
          internalFeedback: Maybe(NewInternalFeedbackDetail)
        })),
        pageNum: t.number,
        pageSize: t.number,
        totalItem: t.number,
      })
    }))(inputData))
  )


  export const getNewFeedbackDetailData = (suspendID: string, policyNum: string) =>
    pipe(
      AuthService.getInternalCachedToken(),
      ZIO.flatMap((token) => POApi.internalAuthorizedGet(`feedback-rest-internal/api/v1/internal-feedback?suspendID=${suspendID}&policyNum=${policyNum}`, { headers: { Authorization: `Bearer ${token}` } })(t.type({
        data: t.type({
          policy: t.type({
            firstName: t.string,
            lastName: t.string,
            owner: t.string
          }),
          internalSuspend: t.intersection([
            ResponseInternalSuspendData,
            t.type({
              departmentShortName: t.string,
              departmentEN: t.string,
              departmentVN: t.string,
              // departmentWF: t.string,
              isFeedback: t.boolean,
              isSuspend: t.boolean
            })]),
          internalFeedback: PreviousFeedbackData,
          suspendExpiredDateResponse: t.type({
            planUnsuspendDate: Maybe(t.string)
          })
        })
      })))
    )
  export const submitNewFeedBack = (submitedData: NewSavedInternalFeedbackRequest) => pipe(
    AuthService.token,
    ZIO.flatMap((token) => POApi.internalAuthorizedPost(`feedback-rest-internal/api/v1/internal-feedback/submit-feedback`, { headers: { Authorization: `Bearer ${token}` } })(t.type({
      code: t.string,
      message: t.string,
      data: Maybe(t.type({
        parentId: Maybe(t.string),
        feedbackDate: Maybe(t.string),
        feedbackUser: Maybe(t.string),
        feedbackDetail: Maybe(t.string),
        feedbackDepartment: Maybe(t.string),
        feedbackDepartmentShortName: Maybe(t.string),
        feedbackDepartmentEN: Maybe(t.string),
        feedbackDepartmentVN: Maybe(t.string),
        feedbackDepartmentWF: Maybe(t.string)
      }))
    }))(submitedData))
  )
}