import { OBInternalFeedbackStackParamList } from './OBInternalFeedbackStackParamList'
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { OBInternalFeedbackScreen } from './OBInternalFeedbackScreen'
import { OBRenewInternalFeedbackScreen } from "./OBRenewInternalFeedbackScreen"
const Stack = createStackNavigator<OBInternalFeedbackStackParamList>()

export const OBInternalFeedbackStack = () => {
  return (
    <Stack.Navigator initialRouteName="OBRenewInternalFeedbackScreen" screenOptions={{ headerShown: false }}>
      {/* <Stack.Screen name="OBInternalFeedbackScreen" component={OBInternalFeedbackScreen}></Stack.Screen> */}
      <Stack.Screen name="OBRenewInternalFeedbackScreen" component={OBRenewInternalFeedbackScreen}></Stack.Screen>
    </Stack.Navigator>
  )
}
